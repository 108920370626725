import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Send, Check, AlertTriangle, Loader2, MapPin, Phone, Mail } from 'lucide-react';

// Configurações de ambiente
const EMAIL_SERVICE_ID = process.env.REACT_APP_EMAIL_SERVICE_ID;
const EMAIL_TEMPLATE_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID;
const EMAIL_USER_ID = process.env.REACT_APP_EMAIL_USER_ID;

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validações avançadas
  const validateForm = () => {
    const newErrors = {};

    // Validação de nome
    if (!formData.name.trim()) {
      newErrors.name = 'Nome é obrigatório';
    } else if (formData.name.trim().length < 2) {
      newErrors.name = 'Nome deve ter pelo menos 2 caracteres';
    }

    // Validação de email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = 'E-mail é obrigatório';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'E-mail inválido';
    }

    // Validação de mensagem
    if (!formData.message.trim()) {
      newErrors.message = 'Mensagem é obrigatória';
    } else if (formData.message.trim().length < 10) {
      newErrors.message = 'Mensagem muito curta';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Limpar erro específico quando o usuário começa a digitar
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação antes do envio
    if (!validateForm()) return;

    setIsSubmitting(true);
    setStatus('');

    try {
      const result = await emailjs.sendForm(
        EMAIL_SERVICE_ID,
        EMAIL_TEMPLATE_ID,
        e.target,
        EMAIL_USER_ID
      );

      // Sucesso
      setStatus('success');
      setFormData({ name: '', email: '', message: '' });

      // Resetar após 3 segundos
      setTimeout(() => setStatus(''), 3000);
    } catch (error) {
      // Erro
      setStatus('error');
      console.error('Erro ao enviar:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact">
    <div className="min-h-screen bg-gradient-to-br from-blue-200 via-blue-400 flex items-center justify-center p-4 relative overflow-hidden">
      {/* Camadas de nuvens sutis */}
      <div className="absolute inset-0 opacity-30 pointer-events-none">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-100 via-blue-200 to-transparent opacity-50"></div>
        <div className="absolute bottom-0 right-0 w-full h-full bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-purple-100 via-purple-200 to-transparent opacity-30"></div>
      </div>
      <div className="w-full max-w-6xl bg-white/90 backdrop-blur-sm shadow-2xl rounded-2xl grid grid-cols-1 md:grid-cols-2 relative z-10">
        {/* Seção de Informações de Contato */}
        <div className="bg-gradient-to-br from-blue-600 to-purple-600 text-white p-8 md:p-12 flex flex-col justify-center rounded-l-2xl">
          <h2 className="text-3xl font-bold mb-8">Informações de Contato</h2>

          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <MapPin className="w-7 h-7 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-blue-100">Localização</h3>
                <p className="text-lg">Tubarão, SC</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Phone className="w-7 h-7 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-blue-100">Telefone</h3>
                <p className="text-lg">(48)98865-4998</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Mail className="w-7 h-7 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-blue-100">E-mail</h3>
                <p className="text-lg">patrick@pzmsolucoes.com.br</p>
              </div>
            </div>
          </div>
        </div>

        {/* Formulário de Contato */}
        <div className="p-8 md:p-12 flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Entre em Contato
          </h2>

          <form onSubmit={handleSubmit} noValidate>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">Nome</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-lg transition duration-300
                  ${errors.name
                    ? 'border-red-500 focus:ring-2 focus:ring-red-200'
                    : 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
                  }`}
                placeholder="Seu nome"
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1 flex items-center">
                  <AlertTriangle className="mr-2 w-4 h-4" /> {errors.name}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">E-mail</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-4 py-3 border rounded-lg transition duration-300
                  ${errors.email
                    ? 'border-red-500 focus:ring-2 focus:ring-red-200'
                    : 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
                  }`}
                placeholder="seuemail@exemplo.com"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1 flex items-center">
                  <AlertTriangle className="mr-2 w-4 h-4" /> {errors.email}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Mensagem</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                className={`w-full px-4 py-3 border rounded-lg transition duration-300 resize-none
                  ${errors.message
                    ? 'border-red-500 focus:ring-2 focus:ring-red-200'
                    : 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
                  }`}
                placeholder="Escreva sua mensagem..."
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-sm mt-1 flex items-center">
                  <AlertTriangle className="mr-2 w-4 h-4" /> {errors.message}
                </p>
              )}
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full py-3 rounded-lg text-white font-semibold transition duration-300
                ${isSubmitting
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'
                }`}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <Loader2 className="mr-2 animate-spin" />
                  Enviando...
                </span>
              ) : (
                <span className="flex items-center justify-center">
                  <Send className="mr-2" /> Enviar Mensagem
                </span>
              )}
            </button>

            {status === 'success' && (
              <div className="mt-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                <div className="flex items-center">
                  <Check className="mr-2 w-5 h-5" />
                  <p>Mensagem enviada com sucesso!</p>
                </div>
              </div>
            )}

            {status === 'error' && (
              <div className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <div className="flex items-center">
                  <AlertTriangle className="mr-2 w-5 h-5" />
                  <p>Erro ao enviar. Tente novamente.</p>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
    </section>
  );
}

export default ContactForm;
