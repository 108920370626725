import React from 'react';
import { motion } from 'framer-motion';
import logo from "../../assets/logopzm.png";

function Footer() {
  return (
    <motion.footer
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
      className="bg-gradient-to-br from-gray-800 via-gray-900 to-black text-white  shadow-lg p-6"
    >
      <div className="w-full max-w-screen-xl mx-auto md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img src={logo} className="h-10" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap">PZM Soluções Digitais</span>
          </motion.div>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-400 sm:mb-0">
            <motion.li
              whileHover={{ scale: 1.1 }}
              className="me-4 md:me-6"
            >
              <a href="#about" className="hover:underline">Sobre</a>
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.1 }}
            >
              <a href="#contact" className="hover:underline">Contato</a>
            </motion.li>
          </ul>
        </div>
        <hr className="my-6 border-gray-700 sm:mx-auto lg:my-8" />
        <span className="block text-sm text-gray-400 sm:text-center">© 2024 <p className="hover:underline">PZM Soluções Digitais</p>. Todos os Direitos Reservados.</span>
      </div>
    </motion.footer>
  );
}

export default Footer;
