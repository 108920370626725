import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import projeto1 from '../../assets/patinhas.jpg';
import projeto2 from '../../assets/img3.jpg';
import projeto3 from '../../assets/img2.jpg';

function ModernFeatureWithPortfolio() {
  const benefits = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-blue-600">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      ),
      title: "Expandir seus Negócios",
      description: "Sua presença digital é essencial. Marcas de sucesso têm uma identidade online forte que atrai e retém clientes."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-green-600">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      ),
      title: "Visibilidade no Google",
      description: "Conquiste autoridade nos resultados de busca e seja encontrado facilmente por potenciais clientes."
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 text-purple-600">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      title: "Autoridade no Mercado",
      description: "Demonstre expertise através de conteúdo relevante e conquiste a confiança dos seus clientes."
    }
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  // Utilizando placeholder images
  const projects = [
    {
      title: "Patinhas Unidas",
      image: projeto1,
      description: "Site desenvolvido para uma ONG de proteção animal"
    },
    {
      title: "Controle de Estoque",
      image: projeto2,
      description: "Sistema completo para gerenciamento de estoque de produtos"
    },
    {
      title: "Cadastro de Médicos",
      image: projeto3,
      description: "Sistema completo para gerenciamento de médicos"
    }
  ];

  const nextProject = () => {
    setCurrentProjectIndex((prev) => (prev + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProjectIndex((prev) => (prev - 1 + projects.length) % projects.length);
  };

  return (
    <section className="relative overflow-hidden bg-gradient-to-br from-blue-50 via-blue-100 to-white py-16 px-4">
      {/* Subtle Cloud-like Background */}
      <div className="absolute inset-0 opacity-30 pointer-events-none">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-100 via-blue-200 to-transparent opacity-50"></div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="container mx-auto max-w-6xl relative z-10"
      >
        <h1 className="text-center text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-500 mb-12 tracking-tight">
          Benefícios de Possuir um Site Profissional
        </h1>

        <div className="grid md:grid-cols-2 gap-12 items-center">
          {/* Benefits Column */}
          <div className="space-y-6">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{
                  delay: index * 0.2,
                  duration: 0.6
                }}
                whileHover={{
                  scale: 1.03,
                  transition: { duration: 0.2 }
                }}
                className="bg-white p-6 rounded-3xl shadow-xl hover:shadow-2xl border border-blue-50/50 transform transition-all group relative overflow-hidden"
              >
                {/* Subtle Cloud Gradient */}
                <div className="absolute inset-0 bg-gradient-to-br from-white/30 to-blue-50/10 opacity-50 pointer-events-none"></div>

                <div className="relative z-10">
                  <div className="flex items-center space-x-4 mb-3">
                    <div className="p-3 bg-blue-50 rounded-full shadow-md">
                      {benefit.icon}
                    </div>
                    <h3 className="text-xl font-bold text-gray-800">
                      {benefit.title}
                    </h3>
                  </div>
                  <p className="text-gray-600 group-hover:text-gray-800 transition-colors">
                    {benefit.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Portfolio Carousel Column */}
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-500 mb-8">
              Meus Trabalhos
            </h2>

            {projects.length > 0 ? (
              <div className="relative w-full max-w-md">
                {/* Botão Anterior */}
                {projects.length > 1 && (
                  <button
                    onClick={prevProject}
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                  >
                    ←
                  </button>
                )}

                {/* Projeto Atual */}
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentProjectIndex}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.5 }}
                    className="flex flex-col items-center"
                  >
                    <img
                      src={projects[currentProjectIndex].image}
                      alt={projects[currentProjectIndex].title}
                      className="w-full rounded-2xl shadow-2xl object-cover mb-6 hover:scale-105 transition-transform"
                    />
                    <div className="text-center">
                      <h3 className="text-2xl font-bold text-gray-800 mb-2">
                        {projects[currentProjectIndex].title}
                      </h3>
                      <p className="text-gray-600 max-w-2xl">
                        {projects[currentProjectIndex].description}
                      </p>
                    </div>
                  </motion.div>
                </AnimatePresence>

                {/* Botão Próximo */}
                {projects.length > 1 && (
                  <button
                    onClick={nextProject}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                  >
                    →
                  </button>
                )}

                {/* Indicadores de Posição */}
                {projects.length > 1 && (
                  <div className="flex justify-center mt-6 space-x-2">
                    {projects.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentProjectIndex(index)}
                        className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                          index === currentProjectIndex
                            ? 'bg-blue-600'
                            : 'bg-gray-300 hover:bg-blue-300'
                        }`}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <p className="text-gray-600">Nenhum projeto cadastrado ainda</p>
            )}
          </div>
        </div>
      </motion.div>
    </section>
  );
}

export default ModernFeatureWithPortfolio;
