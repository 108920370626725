import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { motion } from 'framer-motion';
import foto1 from '../../assets/portfolio.jpg';
import foto2 from '../../assets/e-commerce.jpg';
import foto3 from '../../assets/e-commerce2.jpg';

const images = [
  { src: foto1, alt: "Portfolio" },
  { src: foto2, alt: "E-commerce Project 1" },
  { src: foto3, alt: "E-commerce Project 2" }
];

const Carrossel = () => {
  return (
    <div className="relative w-[95%] mx-auto overflow-hidden">
      {/* Camadas de nuvem sutis */}
      <div className="absolute inset-0 opacity-30 pointer-events-none z-0">
        <div className="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-blue-100 via-blue-200 to-transparent opacity-50"></div>
        <div className="absolute bottom-0 right-0 w-full h-full bg-[radial-gradient(ellipse_at_bottom_right,_var(--tw-gradient-stops))] from-purple-100 via-purple-200 to-transparent opacity-30"></div>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="relative z-10"
      >
        <Carousel
          infiniteLoop
          autoPlay
          interval={3000}
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          swipeable
          dynamicHeight
          transitionTime={500}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = {
              marginLeft: 20,
              color: "blue",
              cursor: "pointer",
              display: "inline-block",
              width: isSelected ? "12px" : "8px",
              height: isSelected ? "12px" : "8px",
              borderRadius: "50%",
              background: isSelected ? "linear-gradient(45deg, #3b82f6, #8b5cf6)" : "#cbd5e1"
            };
            return (
              <li
                style={defStyle}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
        >
          {images.map((img, idx) => (
            <motion.div
              key={idx}
              style={{ position: 'relative' }}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <img
                src={img.src}
                alt={img.alt}
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '12px',
                  boxShadow: '0 10px 25px rgba(0,0,0,0.1)'
                }}
              />
            </motion.div>
          ))}
        </Carousel>
      </motion.div>
    </div>
  );
};

export default Carrossel;
